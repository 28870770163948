var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { COLORS } from 'renderer/styles';
import Button from 'antd/lib/button';
import { useWindowSize } from 'renderer/utils';
import { typographyByName, Icon } from 'renderer/components';
import styled, { createGlobalStyle, css } from 'styled-components';
import { useRecoilState } from 'recoil';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Radio from 'antd/lib/radio';
import { Atoms } from 'renderer/stores';
import Upload from 'antd/lib/upload';
import ReactDom from 'react-dom';
export var ResponsiveWithPlusIcon = function (props) {
    var isPC = useWindowSize().isPC;
    return isPC ? (React.createElement(AntLeftIconButton, __assign({ children: props.children, type: "primary" }, props, { icon: React.createElement(Icon, { name: "plus", size: 18 }) }))) : (React.createElement(AntPrimaryTextButton, __assign({ type: "text" }, props, { icon: React.createElement(Icon, { name: "plus", size: 18 }) })));
};
export var AntPrimaryTextButton = styled(Button).attrs(function (props) { return ({ type: 'text' }); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  & span {\n   ", "} \n   color: ", "\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  & span {\n   ", "} \n   color: ", "\n  }\n"])), css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n     ", ";\n   "], ["\n     ", ";\n   "])), typographyByName('body2')), COLORS.primary1);
export var ButtonWithPlusCircle = function (props) {
    var isMinus = props.isMinus, rest = __rest(props, ["isMinus"]);
    return (React.createElement(StyledButtonWithPlusCircle, __assign({ type: "text", icon: React.createElement(Icon, { name: isMinus ? 'minus-circle' : 'plus-circle', color: COLORS.primary1, size: 20 }) }, rest)));
};
var StyledButtonWithPlusCircle = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  & > svg {\n    margin-right: 10px;\n  }\n  & > span {\n    color: ", ";\n    ", "} \n  }\n"], ["\n  display: flex;\n  align-items: center;\n  & > svg {\n    margin-right: 10px;\n  }\n  & > span {\n    color: ", ";\n    ", "} \n  }\n"])), COLORS.primary1, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ", ";\n    "])), typographyByName('headline3')));
export var ResetButton = function (props) {
    return React.createElement(StyledResetButton, __assign({ icon: React.createElement(Icon, { name: "rotate", color: COLORS.calendarRedActive }) }, props));
};
var StyledResetButton = styled(StyledButtonWithPlusCircle).attrs({ type: 'text' })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & > span {\n    color: ", ";\n    font-weight: 400;\n    font-size: 14px;\n  }\n"], ["\n  & > span {\n    color: ", ";\n    font-weight: 400;\n    font-size: 14px;\n  }\n"])), COLORS.black);
export var ClickableImageButton = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-right: 5px;\n"], ["\n  cursor: pointer;\n  margin-right: 5px;\n"])));
export var HiddenSubmitButton = styled.button.attrs({ children: '' })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var RegisterButtonWithOptions = function (props) {
    var registerType = props.registerType, rest = __rest(props, ["registerType"]);
    var _a = useRecoilState(registerType === 'sales' ? Atoms.salesRegisterOffOptionState : Atoms.scheduleRegisterOffOptionState), value = _a[0], setValue = _a[1];
    return (React.createElement(StyledDropdownButton, null,
        React.createElement(Dropdown.Button, __assign({ type: "primary" }, rest, { trigger: ['click'], icon: React.createElement(Icon, { name: "up-chevron", size: 18, color: COLORS.white }), overlay: React.createElement(Menu, null,
                React.createElement(Menu.Item, null,
                    React.createElement(Radio, { children: "\uB4F1\uB85D\uB41C \uB0B4\uC5ED \uD56D\uC0C1 \uBCF4\uAE30", checked: value === 'details', onClick: function () { return setValue('details'); } })),
                React.createElement(Menu.Item, null,
                    React.createElement(Radio, { children: "\uB4F1\uB85D\uB41C \uB0B4\uC5ED \uD56D\uC0C1 \uB044\uAE30", checked: value === 'imediately', onClick: function () { return setValue('imediately'); } }))) }))));
};
var StyledDropdownButton = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  & .ant-btn-group {\n    width: 100%;\n  }\n  & button:first-child {\n    width: calc(100% - 40px) !important;\n  }\n  & button:nth-child(2) {\n    width: 40px !important;\n  }\n"], ["\n  width: 100%;\n  & .ant-btn-group {\n    width: 100%;\n  }\n  & button:first-child {\n    width: calc(100% - 40px) !important;\n  }\n  & button:nth-child(2) {\n    width: 40px !important;\n  }\n"])));
export var FloatingButtonGroup = function (props) {
    var visible = props.visible, _a = props.confirmButtonProps, confirmButtonProps = _a === void 0 ? {} : _a, _b = props.cancelButtonProps, cancelButtonProps = _b === void 0 ? {} : _b;
    return (React.createElement(React.Fragment, null,
        visible && React.createElement(ChanneltalkDisplayNone, null),
        ReactDom.createPortal(React.createElement(FloatingButtonGroupWrapper, { "$visible": visible },
            React.createElement(AntButton, __assign({ children: "\uCD08\uAE30\uD654" }, cancelButtonProps)),
            React.createElement(AntButton, __assign({ children: "\uC800\uC7A5", type: "primary" }, confirmButtonProps))), document.body)));
};
var FloatingButtonGroupWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  bottom: 0px;\n  left: 0px;\n  background-color: white;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  z-index: 99999;\n\n  height: ", ";\n  opacity: ", ";\n\n  transition: height 0.5s;\n\n  & > button {\n    width: 100px;\n    &:first-child {\n      margin-right: 10px;\n    }\n  }\n  padding-left: 20px;\n  padding-right: 20px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n"], ["\n  position: fixed;\n  width: 100%;\n  bottom: 0px;\n  left: 0px;\n  background-color: white;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  z-index: 99999;\n\n  height: ", ";\n  opacity: ", ";\n\n  transition: height 0.5s;\n\n  & > button {\n    width: 100px;\n    &:first-child {\n      margin-right: 10px;\n    }\n  }\n  padding-left: 20px;\n  padding-right: 20px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n"])), function (props) { return (props.$visible ? '60px' : '0px'); }, function (props) { return (props.$visible ? 1 : 0); });
export var ChanneltalkDisplayNone = createGlobalStyle(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    #ch-plugin-entry {\n        display:none !important;\n    }\n"], ["\n    #ch-plugin-entry {\n        display:none !important;\n    }\n"])));
export var AntButton = styled(Button)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return (props.$fluid ? '100%' : 'auto'); });
export var AntPrimaryGhostButton = styled(AntButton).attrs({ type: 'primary', ghost: true })(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
export var UploadButton = function (props) { return (React.createElement(FullWidthUpload, __assign({ listType: "picture-card", showUploadList: false }, props),
    React.createElement("div", null,
        React.createElement(Icon, { name: "plus" }),
        React.createElement("div", { style: { marginTop: 8 } }, "\uC0AC\uC9C4 \uCD94\uAC00")))); };
var FullWidthUpload = styled(Upload)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  height: 140px;\n  & > .ant-upload-select {\n    border-radius: 2px !important;\n    width: 100% !important;\n    height: 100% !important;\n  }\n"], ["\n  width: ", ";\n  height: ", ";\n  height: 140px;\n  & > .ant-upload-select {\n    border-radius: 2px !important;\n    width: 100% !important;\n    height: 100% !important;\n  }\n"])), function (props) { return "".concat(props.width, "px"); }, function (props) { return "".concat(props.width, "px"); });
export var AntLeftIconButton = function (props) {
    return React.createElement(AntLeftIconWrapper, __assign({}, props));
};
var AntLeftIconWrapper = styled(AntButton)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var FullWidthButton = styled(AntButton)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
